import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../axios';
import {
  PubMedArticle,
  PubMedArticleTranslation,
  AustrianPubMedArticle,
  AustrianPubMedSearchResponse,
  Rubric,
} from '../types';
import { RootState, useAppSelector } from '../store';
import PaginationControls from './PaginationControls';
import PaywallModal from './PaywallModal';
import AdaptiveTitle from './AdaptiveTitle';
import { ArticleGrid } from './ArticleGrid';
import { FeaturedLayout } from './FeaturedLayout';
import AwayClickablePaywallModal from './AwayClickablePaywallModal';
import { AustrianArticleCard } from './AustrianArticleCard';
import { useAuthInitialization } from '../hooks/useAuthInitialization';

interface RubricArticles {
  rubricId: number;
  rubricName: string;
  articles: PubMedArticle[];
  currentPage: number;
  totalPages: number;
}

const useResponsivePageSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate page size based on screen width
  if (windowWidth >= 1536) {
    return 8; // 2xl: 4 columns, 2 rows = 8 articles
  } else if (windowWidth >= 1024) {
    return 6; // lg: 3 columns, 2 rows = 6 articles
  } else if (windowWidth >= 640) {
    return 4; // sm: 2 columns, 2 rows = 4 articles
  } else {
    return 3; // mobile: 1 column, 3 rows = 3 articles
  }
};

const PubMedDisplay = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  // Redux state
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  
  const { user, initialized: authInitializedFlag } = useAppSelector((state) => state.auth)

  // Access control
  const hasFullAccess = user?.roles?.includes('PERMISSION_ADMIN') || 
    user?.roles?.includes('PERMISSION_PAID_ACCESS');


  // Featured layout state (fallback if no rubrics)
  const [featuredArticle, setFeaturedArticle] = useState<PubMedArticle | null>(null);
  const [sideArticles, setSideArticles] = useState<PubMedArticle[]>([]);
  const [gridArticles, setGridArticles] = useState<PubMedArticle[]>([]);
  const { isFullyReady, userRubrics } = useAuthInitialization();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  
  // Main content state with pagination
  const [latestRubricsData, setLatestRubricsData] = useState<RubricArticles[]>([]);
  const [topCitedRubricsData, setTopCitedRubricsData] = useState<RubricArticles[]>([]);
  const [showPaywall, setShowPaywall] = useState(false);

  // Austrian articles state
  const [austrianArticles, setAustrianArticles] = useState<AustrianPubMedArticle[]>([]);
  const [austrianLoading, setAustrianLoading] = useState(true);
  const [austrianPage, setAustrianPage] = useState(0);
  const [austrianTotalPages, setAustrianTotalPages] = useState(0);
  const SERVER_SIZE_PER_RUBRIC = useResponsivePageSize();
  const AUST_PAGE_SIZE = useResponsivePageSize();

  // UI state
  const [loading, setLoading] = useState(true);
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);

  // URL params
  const selectedRubricId = useMemo(() => {
    const val = searchParams.get('rubric');
    return val ? Number(val) : null;
  }, [searchParams]);

  
  // Helper function to fetch articles for a specific rubric
  const fetchArticlesForRubric = async (
    rubricId: number,
    rubricName: string,
    endpoint: 'latest' | 'top-cited',
    page: number
  ) => {
    const url = `/api/pubmedarticles/rubric/${rubricId}/${endpoint}`;
    const resp = await axios.get(url, {
      params: { page, size: SERVER_SIZE_PER_RUBRIC },
    });

    let articles: PubMedArticle[] = [];
    let totalPages = 1;

    if (resp.data?.content) {
      articles = resp.data.content;
      totalPages = resp.data.totalPages;
    } else if (Array.isArray(resp.data)) {
      articles = resp.data;
      totalPages = Math.ceil(resp.data.length / SERVER_SIZE_PER_RUBRIC);
    }
    console.log("articles: ", articles)


    return { articles, totalPages };
  };

  // Main fetch function for latest and top-cited articles
  const fetchArticles = useCallback(async () => {
    setLoading(true);
    try {
      let rubricIds: number[] = [];
      console.log('rubrics: ', userRubrics)
      if (selectedRubricId) {
        rubricIds = [selectedRubricId];
      } else if (userRubrics.length > 0) {
        rubricIds = userRubrics.map(r => r.id);
      }

      // If no rubrics, show featured layout
      if (rubricIds.length === 0) {
        const resp = await axios.get<PubMedArticle[]>('/api/pubmedarticles/top-by-rubric/today');
        const articles = resp.data || [];
        if (articles.length > 0) {
          const sorted = [...articles].sort(
            (a, b) => (b.citationCount || 0) - (a.citationCount || 0)
          );
          console.log("articles: ", articles)

          setFeaturedArticle(sorted[0]);
          setSideArticles(sorted.slice(1, 3));
          setGridArticles(sorted.slice(3));
        } else {
          setFeaturedArticle(null);
          setSideArticles([]);
          setGridArticles([]);
        }
        setLatestRubricsData([]);
        setTopCitedRubricsData([]);
        return;
      }

      const newLatestData: RubricArticles[] = [];
      const newTopData: RubricArticles[] = [];

      // Fetch data for each rubric
      for (const rubId of rubricIds) {
        const foundRubric = userRubrics.find(r => r.id === rubId);
        const name = foundRubric ? foundRubric.name : `Rubric #${rubId}`;

        // Fetch latest articles
        const { articles: latestArticles, totalPages: latestPages } = 
          await fetchArticlesForRubric(rubId, name, 'latest', 0);
        
        // Fetch top-cited articles
        const { articles: topArticles, totalPages: topPages } = 
          await fetchArticlesForRubric(rubId, name, 'top-cited', 0);

        newLatestData.push({
          rubricId: rubId,
          rubricName: name,
          articles: latestArticles,
          currentPage: 0,
          totalPages: latestPages
        });

        newTopData.push({
          rubricId: rubId,
          rubricName: name,
          articles: topArticles,
          currentPage: 0,
          totalPages: topPages
        });
      }

      // Clear featured layout when showing rubrics
      setFeaturedArticle(null);
      setSideArticles([]);
      setGridArticles([]);

      setLatestRubricsData(newLatestData);
      setTopCitedRubricsData(newTopData);

    } catch (err) {
      console.error('Error fetching PubMed articles:', err);
    } finally {
      setLoading(false);
    }
  }, [selectedRubricId, userRubrics]);



  // Handle pagination for both latest and top-cited sections
  const handlePageChange = async (
    rubricId: number,
    newPage: number,
    type: 'latest' | 'top-cited'
  ) => {
    const dataArray = type === 'latest' ? latestRubricsData : topCitedRubricsData;
    const setDataArray = type === 'latest' ? setLatestRubricsData : setTopCitedRubricsData;
    
    const rubricIndex = dataArray.findIndex(r => r.rubricId === rubricId);
    if (rubricIndex === -1) return;

    const rubric = dataArray[rubricIndex];
    const { articles, totalPages } = await fetchArticlesForRubric(
      rubricId,
      rubric.rubricName,
      type,
      newPage
    );

    const newData = [...dataArray];
    newData[rubricIndex] = {
      ...rubric,
      articles,
      currentPage: newPage,
      totalPages
    };

    setDataArray(newData);
  };

  // Fetch Austrian articles
  const fetchAustrianArticles = useCallback(async () => {
    setAustrianLoading(true);
    try {
      const resp = await axios.get<AustrianPubMedSearchResponse>('/api/austrian-articles', {
        params: {
          page: austrianPage,
          size: AUST_PAGE_SIZE,
        },
      });
      if (resp.data?.content) {
        setAustrianArticles(resp.data.content);
        console.log("austrian: ", resp.data.content)
        setAustrianTotalPages(resp.data.totalPages);
      }
    } catch (error) {
      console.error('Error fetching Austrian articles:', error);
    } finally {
      setAustrianLoading(false);
    }
  }, [austrianPage]);

  // Initial data fetch
  useEffect(() => {
    if (isFullyReady) {
      const loadAllContent = async () => {
        setLoading(true);
        try {
          await Promise.all([
            fetchArticles(),
            fetchAustrianArticles()
          ]);
        } finally {
          setLoading(false);
          setInitialLoadComplete(true);
        }
      };
      loadAllContent();
    }
  }, [isFullyReady, fetchArticles, fetchAustrianArticles]);
  
  // Event handlers
  const handlePaywallModalClose = () => setIsPaywallModalOpen(false);

  const handleRubricClick = useCallback(
    (rubricId: number | undefined) => {
      if (!rubricId) return;
      
      if (hasFullAccess) {
        const newRubricId = rubricId === selectedRubricId ? null : rubricId;
        if (newRubricId) {
          setSearchParams({ rubric: newRubricId.toString() }, { replace: true });
        } else {
          setSearchParams({}, { replace: true });
        }
      } else {
        setShowPaywall(true);
      }
    },
    [hasFullAccess, selectedRubricId, setSearchParams]
  );

  const handleReadFullStudy = useCallback(
    (pmid: string, isAustrian: boolean = false) => {
      if (isAustrian && !hasFullAccess) {
        setShowPaywall(true);
        return;
      }
      navigate(`/pubmed/${pmid}`);
    },
    [navigate, hasFullAccess]
  );

  const handleAustrianPageChange = (newPage: number) => setAustrianPage(newPage);

  // Helper functions
  const getTranslation = useCallback(
    (article: PubMedArticle | AustrianPubMedArticle): PubMedArticleTranslation | null => {
      if (!article.translations?.length) return null;
      return article.translations.find(t => t.languageCode === currentLanguage) || null;
    },
    [currentLanguage]
  );

  const getImageUrl = (name: string | undefined, size: 'big' | 'small' = 'big') => {
    if (!name) return '';
    let imageName = name
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss') + '.png';
    if (size === 'big') {
      imageName = 'big/' + imageName;
    }
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  const isRecent = (article: PubMedArticle) =>
    new Date(article.publicationDate).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000;

  const isRecentAustrian = (article: AustrianPubMedArticle) =>
    new Date(article.publicationDate).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000;
  

  if (!isFullyReady) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        {t('common.loading')}...
      </div>
    );
  }

  return (
    <div>
      {showPaywall && <AwayClickablePaywallModal onClose={() => setShowPaywall(false)} />}

      {/* Access upgrade banner */}
      {!hasFullAccess && (
        <div className="lg:mb-12 mb-4 rounded-lg lg:p-2">
          <p className="text-[#49ABDB] font-medium mb-2">
            {t('pubmed.limitedAccess')}
          </p>
          <a
            href="/signup"
            className="inline-block bg-[#49ABDB] text-white px-6 py-2 rounded-full font-medium hover:bg-[#3891c0] transition-colors"
          >
            {t('auth.getStarted')}
          </a>
        </div>
      )}
  
      {/* Main content */}
      {latestRubricsData.length === 0 && topCitedRubricsData.length === 0 ? (
        // Featured layout (fallback)
        featuredArticle && (
          <FeaturedLayout
            featuredArticle={featuredArticle}
            sideArticles={sideArticles}
            gridArticles={gridArticles}
            onRubricClick={handleRubricClick}
            onReadStudy={handleReadFullStudy}
            getTranslation={getTranslation}
            getImageUrl={getImageUrl}
            isRecent={isRecent}
            t={t}
          />
        )
      ) : (
        <>
          {/* Latest Studies */}
          <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-6">
            {t('pubmed.latestStudies')}
          </h2>
          {latestRubricsData.map(({ rubricId, rubricName, articles, currentPage, totalPages }) => (
            <div key={rubricId} className="mb-8">
              <ArticleGrid
                articles={articles}
                onRubricClick={handleRubricClick}
                onReadStudy={handleReadFullStudy}
                getTranslation={getTranslation}
                getImageUrl={getImageUrl}
                isRecent={isRecent}
                t={t}
              />
              <div className="mt-4">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(rubricId, newPage, 'latest')}
                />
              </div>
            </div>
          ))}
  
          {/* Top Cited Studies */}
          <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-6 mt-8">
            {t('pubmed.topCitedStudies')}
          </h2>
          {topCitedRubricsData.map(({ rubricId, rubricName, articles, currentPage, totalPages }) => (
            <div key={rubricId} className="mb-8">
              <ArticleGrid
                articles={articles}
                onRubricClick={handleRubricClick}
                onReadStudy={handleReadFullStudy}
                getTranslation={getTranslation}
                getImageUrl={getImageUrl}
                isRecent={isRecent}
                t={t}
              />
              <div className="mt-4">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(rubricId, newPage, 'top-cited')}
                />
              </div>
            </div>
          ))}
        </>
      )}
  
      {/* Austrian Articles Section */}
      {austrianArticles.length > 0 && (
        <div className="mt-16">
          <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-8">
            {t('pubmed.austrianStudies')}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
            {austrianArticles.map((article) => (
              <AustrianArticleCard
                key={article.pmid}
                article={article}
                onReadStudy={handleReadFullStudy}
                getTranslation={getTranslation}
                t={t}
              />
            ))}
          </div>
          <div className="mt-4">
            <PaginationControls
              currentPage={austrianPage}
              totalPages={austrianTotalPages}
              onPageChange={handleAustrianPageChange}
            />
          </div>
        </div>
      )}
  
      {/* Upgrade Modal */}
    </div>
  );

};
export default PubMedDisplay;