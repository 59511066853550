// AwayClickablePaywallModal.tsx
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';

interface PaywallModalProps {
  disableClickAway?: boolean;
  onClose?: () => void;
}

const AwayClickablePaywallModal: React.FC<PaywallModalProps> = ({
  disableClickAway = false,
  onClose,
}) => {
  const navigate = useNavigate();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const user = useSelector((state: RootState) => state.auth.user);
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  // useCallback ensures the handler identity is stable.
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!disableClickAway && modalRef.current && !modalRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [disableClickAway]
  );

  useEffect(() => {
    if (isOpen) {
      // Disable scrolling when the modal is open.
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // When the modal is closed, immediately undo side effects.
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup on unmount.
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  // Notify parent when modal is closed.
  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  // If the modal is no longer open, render nothing.
  if (!isOpen) return null;

  const content = {
    en: {
      title: "Ah … Have you fallen in love with HEARTPORT?",
      description:
        "Become a premium member now, secure your information advantage, and support Heartport in making science accessible to everyone.",
      benefits: [
        "Latest studies matching your specialty",
        "Business skills for doctors",
        "Current science from Austria",
      ],
      cta: user ? "Upgrade Now" : "Sign Up & Upgrade",
    },
    de: {
      title: "Ah … Have you fallen in love with HEARTPORT?",
      description:
        "Werden Sie jetzt Premium-Mitglied, sichern Sie sich Ihren Informationsvorsprung, und unterstützen Sie Heartport dabei, Wissenschaft für alle zugänglich zu machen.",
      benefits: [
        "Neueste Studien passend zu Ihrem Fachgebiet",
        "Business Skills für Ärzte",
        "Aktuelle Wissenschaft aus Österreich",
      ],
      cta: user ? "Jetzt upgraden" : "Registrieren & upgraden",
    },
  };

  const texts = content[currentLanguage === 'de' ? 'de' : 'en'];

  const handleCtaClick = () => {
    // Reset scroll position before navigation.
    window.scrollTo(0, 0);
    if (user) {
      navigate('profile?tab=subscription');
    } else {
      navigate('/signup');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4">
      <div ref={modalRef} className="bg-white w-full max-w-2xl rounded-lg max-h-[90vh] overflow-y-auto">
        <div className="p-4 md:p-8">
          <div className="space-y-4 md:space-y-6">
            <h2 className="text-xl md:text-3xl font-bold text-[#49ABDB] text-center mb-4 md:mb-6">
              {texts.title}
            </h2>

            <p className="text-base md:text-lg text-gray-700 text-center mb-6 md:mb-8 px-2 md:px-4">
              {texts.description}
            </p>

            <div className="bg-gray-50 p-4 md:p-6 rounded-lg">
              <h3 className="text-lg md:text-xl font-semibold text-[#49ABDB] mb-3 md:mb-4">
                {currentLanguage === 'de'
                  ? 'Jetzt Premium-User werden und profitieren:'
                  : 'Become a premium member and enjoy:'}
              </h3>
              <ul className="space-y-2 md:space-y-3">
                {texts.benefits.map((benefit, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 text-[#49ABDB] text-base md:text-lg flex-shrink-0">✓</span>
                    <span className="text-gray-700 text-sm md:text-base">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-6 md:mt-8">
              <button
                onClick={handleCtaClick}
                className="w-full bg-[#49ABDB] text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-semibold text-base md:text-lg hover:bg-[#3d8fb8] transition-colors duration-200"
              >
                {texts.cta}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwayClickablePaywallModal;
