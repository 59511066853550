import { useState, useEffect } from 'react';
import axios from '../axios';
import { useAppDispatch, useAppSelector } from '../store';
import { restoreToken, getCurrentUser } from '../store/authSlice';
import { Rubric } from '../types'; // Import the Rubric type

export const useAuthInitialization = () => {
    const dispatch = useAppDispatch();
    const { initialized, user } = useAppSelector((state) => state.auth);
    const [userRubricsReady, setUserRubricsReady] = useState(false);
    const [userRubrics, setUserRubrics] = useState<Rubric[]>([]);
  
    // Step 1: Initialize auth
    useEffect(() => {
      const initializeAuth = async () => {
        try {
          // First restore token (this is synchronous)
          dispatch(restoreToken());
          
          // If token exists, fetch current user (this is async)
          if (localStorage.getItem('token')) {
            await dispatch(getCurrentUser()).unwrap();
          }
        } catch (error) {
          console.error('Auth initialization failed:', error);
        }
      };
  
      if (!initialized) {
        initializeAuth();
      }
    }, [dispatch, initialized]);
    
    // Step 2: Load user rubrics after auth is initialized
    useEffect(() => {
      const loadRubrics = async () => {
        if (!initialized) return;
        
        if (user?.id) {
          try {
            const resp = await axios.get<Rubric[]>(`/api/admin/users/${user.id}/selected-rubrics`);
            setUserRubrics(resp.data);
          } catch (err) {
            console.error('Error fetching user rubrics:', err);
          }
        }
        
        // Mark rubrics as ready whether we loaded them or not
        setUserRubricsReady(true);
      };
      
      loadRubrics();
    }, [initialized, user?.id]);
  
    // Return all necessary state in one object
    return {
      authInitialized: initialized,
      userRubricsReady,
      isFullyReady: initialized && userRubricsReady,
      userRubrics
    };
};