import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { SubscriptionLevel, SubscriptionStatus } from '../store/authSlice';
import clouds from '../assets/images/clouds.png';
import HearticleStatistics from './HearticleStatistics';
import UserManagement from './UserManagement';
import PlaylistManagement from './PlaylistManagement';
import { Camera } from 'lucide-react';
import axios from '../axios';
import SubscriptionManagement from './SubscriptionManagement';
import RubricSelection from './RubricSelection';

const Profile = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get('tab') || 'profile';

  const [activeTab, setActiveTab] = useState('initialTab');
  
  useEffect(() => {
    // Whenever the URL changes, update the tab
    setActiveTab(initialTab);
  }, [initialTab]);

  // Calculate the daily changing hue value
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };
  const formatPermissionName = (permission: string): string => {
    return permission
      .replace('PERMISSION_', '')
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };


  const handleTabClick = (tabName: string) => {
    navigate(`/profile?tab=${tabName}`);
  };


  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    // Validate file type
    if (!file.type.startsWith('image/')) {
      setUploadError('Please select an image file');
      return;
    }
  
    // Validate file size (e.g., 5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setUploadError('Image size should be less than 5MB');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setUploadError(null);
      
      // Use the configured Axios instance
      const response = await axios.post(
        `/api/admin/users/${user?.id}/profile-image`,
        formData
        // No need to set headers here
      );
  
      // Force a reload of the profile image by adding a timestamp
      const timestamp = new Date().getTime();
      const imageElement = document.getElementById('profile-image') as HTMLImageElement;
      if (imageElement) {
        imageElement.src = `/api/admin/users/${user?.id}/profile-image?t=${timestamp}`;
      }
    } catch (error) {
      console.error('Full error object:', error);
      setUploadError('Failed to upload image. Please try again.');
    }
  };
  
  


  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const renderProfileTab = () => (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-bold text-[#111D4E] mb-4">Account Details</h2>

        <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6">
          <div className="relative group flex justify-center">
            <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-full overflow-hidden bg-gray-200 relative">

              <img
                id="profile-image"
                src={`/api/admin/users/${user?.id}/profile-image`}
                alt={user?.username}
                className="w-full h-full object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.username || '')}&background=random`;
                }}
              />
              <button
                onClick={triggerFileInput}
                className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center"
              >
                <Camera className="6 h-6 sm:w-8 sm:h-8 text-white" />
              </button>
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            {uploadError && (
              <p className="text-red-500 text-sm mt-2">{uploadError}</p>
            )}
          </div>
          <div className="flex-1 w-full grid grid-cols-1 gap-4 sm:gap-6">
            <div>
              <label className="block text-sm font-medium text-[#111D4E] mb-1">
                Username
              </label>
              <p className="text-base sm:text-lg font-medium break-all">{user?.username}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-[#111D4E] mb-1">
                Email
              </label>
              <p className="text-base sm:text-lg font-medium break-all">{user?.email}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Keep the rest of the profile tab content */}
      <div>
        <h2 className="text-xl font-bold text-[#111D4E] mb-4">Permissions</h2>
        <div className="flex flex-wrap gap-2">
          {user?.roles?.map((permission, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-[#49ABDB] text-white rounded-full text-sm"
            >
              {formatPermissionName(permission)}
            </span>
          ))}
        </div>
      </div>

      <div className="space-y-4">
        <h2 className="text-xl font-bold text-[#111D4E]">Security</h2>
        <button className="px-4 py-2 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white font-bold rounded hover:opacity-90 transition-opacity">
          Change Password
        </button>
      </div>
    </div>
  );

  return (
    <div style={backgroundStyle} className="min-h-screen">
      <div style={cloudImageStyle}></div>
      <div style={overlayStyle}></div>

      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-[#49ABDB] mb-8">Profile Settings</h1>

          <div className="bg-[#CAD4DD] rounded-lg shadow-lg p-6">
            <div className="flex flex-wrap gap-2 border-b border-gray-300 mb-6 overflow-x-auto">
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'profile'
                    ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                    : 'text-[#111D4E]'
                }`}
                onClick={() => handleTabClick('profile')}
              >
                Profile Information
              </button>
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'subscription'
                    ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                    : 'text-[#111D4E]'
                }`}
                onClick={() => handleTabClick('subscription')}
              >
                Subscription
              </button>
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'rubrics' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-700'
                }`}
                onClick={() => handleTabClick('rubrics')}
              >
                Rubrics
              </button>
              {user?.roles?.includes('PERMISSION_VIEW_STATISTICS') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'statistics'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => handleTabClick('statistics')}
                >
                  Statistics
                </button>
              )}
              {user?.roles?.includes('PERMISSION_ADMIN') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'users'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => handleTabClick('users')}
                >
                  Users
                </button>
              )}
              {user?.roles?.includes('PERMISSION_PLAYLIST') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'playlists'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => handleTabClick('playlists')}
                >
                  Playlists
                </button>
              )}
            </div>

            {/* Tab Content */}
            {activeTab === 'profile' && renderProfileTab()}

            {activeTab === 'subscription' && user && <SubscriptionManagement />}

            {activeTab === 'rubrics' && <RubricSelection />}

            {activeTab === 'statistics' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <HearticleStatistics />
            )}
            {activeTab === 'users' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <UserManagement />
            )}
            {activeTab === 'playlists' && user?.roles?.includes('PERMISSION_PLAYLIST') && (
              <PlaylistManagement />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;