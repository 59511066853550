import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Book } from 'lucide-react';
import PlayButton from './PlayButton';
import { Hearticle, HearticleTranslation } from '../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import AwayClickablePaywallModal from './AwayClickablePaywallModal';


interface ArticleCardProps {
  article: Hearticle;
  translation: HearticleTranslation;
  imageSize?: 'sm' | 'md' | 'lg';
  className?: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  translation,
  imageSize = 'md',
  className = '',
}) => {
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [showPaywall, setShowPaywall] = useState(false);
  
  const user = useSelector((state: RootState) => state.auth.user);
  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || 
                 user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') || 
                 false;

  const imageSizes = {
    sm: 'w-12 h-12 sm:w-16 sm:h-16',
    md: 'w-16 h-16 sm:w-20 sm:h-20',
    lg: 'w-20 h-20 sm:w-24 sm:h-24 xl:w-32 xl:h-32'
  };

  const iconSizes = {
    sm: 24,
    md: 32,
    lg: 48
  };

  const hasReachedLimit = !isPaid;

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') {
      imageName = 'recover_immunity_&_supplements.png';
    }
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const handleClick = (e: React.MouseEvent) => {
    if (hasReachedLimit) {
      e.preventDefault();
      setShowPaywall(true);
    }
  };


  return (
    <>
      {showPaywall && <AwayClickablePaywallModal onClose={() => setShowPaywall(false)} />}
      <div className={`bg-[#CAD4DD] p-4 relative min-h-[250px] sm:min-h-[280px] flex flex-col ${className}`}>
        {imageError ? (
          <div className={`absolute top-3 right-3 ${imageSizes[imageSize]} flex items-center justify-center`}>
            <Book size={iconSizes[imageSize]} className="text-gray-600" />
          </div>
        ) : (
          <img
            src={getImageUrl(article.topic.name)}
            alt={article.topic.name}
            onError={handleImageError}
            className={`absolute top-3 right-3 object-contain ${imageSizes[imageSize]}`}
          />
        )}

        <div className="pr-20 space-y-3 flex-1">
          <div
            className="px-2 py-1 inline-block rounded text-xs sm:text-sm line-clamp-1"
            style={{
              backgroundColor: article.topic.color,
              color: '#000000',
            }}
          >
            {article.topic.name}
          </div>
          
          <h3 className="text-base sm:text-lg xl:text-xl font-bold text-[#49ABDB] line-clamp-2">
            {translation?.headline || t('noHeadlineAvailable')}
          </h3>
          
          <p className="text-xs sm:text-sm xl:text-base text-black line-clamp-2 italic">
            {translation?.keywords?.join(' - ') || t('noKeywordsAvailable')}
          </p>
        </div>
        
        <div className="flex items-center gap-2">
          <Link
            to={`/hearticle/${article.id}`}
            onClick={handleClick}
            className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
          >
            {t('heartport.readFullHearticle')}
          </Link>
          <div className="mt-2">
            <PlayButton
              hearticleId={article.id}
              translationId={translation.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard;