import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSubscriptionStatus } from '../store/authSlice';
import { AppDispatch } from '../store';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const updateUserSubscription = async () => {
      try {
        // Update the user's subscription status in Redux
        await dispatch(updateSubscriptionStatus()).unwrap();
        
        // Show success message and redirect
        setTimeout(() => {
          navigate('/profile?tab=rubrics');
        }, 2000);
      } catch (error) {
        console.error('Error updating subscription status:', error);
        navigate('/profile?tab=rubrics');
      }
    };

    updateUserSubscription();
  }, [dispatch, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Payment Successful!
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Your subscription has been activated. You will be redirected shortly...
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;