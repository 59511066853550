import React from 'react';
import { AustrianPubMedArticle } from '../types';
import AdaptiveTitle from './AdaptiveTitle';

interface AustrianArticleCardProps {
    article: AustrianPubMedArticle;
    onReadStudy: (pmid: string, isAustrian: boolean) => void;
    getTranslation: (article: AustrianPubMedArticle) => any;
    t: (key: string) => string;
}

export const AustrianArticleCard: React.FC<AustrianArticleCardProps> = ({
    article,
    onReadStudy,
    getTranslation,
    t
}) => {
    // Memoize the translation to prevent unnecessary re-renders
    const translation = React.useMemo(() => 
        getTranslation(article), [article, getTranslation]
    );

    // Memoize authors display
    const authorDisplay = React.useMemo(() => {
        if (!article.authors?.length) return '';
        return article.authors.slice(0, 2).join(', ') + 
               (article.authors.length > 2 ? ' et al.' : '');
    }, [article.authors]);

    return (
        <div className="bg-[#cde1ee] p-4 relative min-h-[300px] xl:h-[370px] flex flex-col overflow-hidden">
            <div className="flex flex-1 min-h-0"> {/* Added min-h-0 */}
                <div className="flex-1 flex flex-col min-h-0"> {/* Added flex-col and min-h-0 */}
                    <div className="px-2 py-1 rounded text-xs xl:text-base bg-[#EF3340] text-white inline-block shrink-0">
                        {article.journal?.name || t('pubmed.austrianJournal')}
                    </div>
                    <div className="flex-1 min-h-0 mt-2"> {/* Wrapper div for AdaptiveTitle */}
                        <AdaptiveTitle
                            text={translation?.translatedTitle || article.title}
                            baseClassName="h-full"
                            maxFontSize={20}
                            minFontSize={11}
                        />
                    </div>
                    <div className="text-xs xl:text-sm text-[#111D4E] mt-2 shrink-0">
                        {authorDisplay}
                    </div>
                </div>
            </div>
            <button
                onClick={() => onReadStudy(article.pmid, true)}
                className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity shrink-0"
            >
                {t('pubmed.readAustrianStudy')}
            </button>
        </div>
    );
};