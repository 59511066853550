import { PubMedArticle } from "../types";
import { PubMedCard } from "./PubMedCard";

interface ArticleGridProps {
    articles: PubMedArticle[];
    onRubricClick: (rubricId: number) => void;
    onReadStudy: (pmid: string) => void;
    getTranslation: (article: PubMedArticle) => any;
    getImageUrl: (name: string | undefined, size?: 'big' | 'small') => string;
    isRecent: (article: PubMedArticle) => boolean;
    t: (key: string) => string;
    title?: string;
}

export const ArticleGrid: React.FC<ArticleGridProps> = ({
    articles,
    onRubricClick,
    onReadStudy,
    getTranslation,
    getImageUrl,
    isRecent,
    t,
    title
}) => {
    return (
        <div className="mb-8">
            {title && (
                <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-8">
                    {title}
                </h2>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                {articles.map((article) => (
                    <PubMedCard
                        key={article.pmid}
                        article={article}
                        onRubricClick={onRubricClick}
                        onReadStudy={onReadStudy}
                        getTranslation={getTranslation}
                        getImageUrl={getImageUrl}
                        isRecent={isRecent}
                        t={t}
                    />
                ))}
            </div>
        </div>
    );
};
