import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'

// Define subscription types
export type SubscriptionLevel = 'FREE' | 'BASIC' | 'PREMIUM' | 'ENTERPRISE'
export type SubscriptionStatus = 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CANCELLED'

// Updated user type with subscription information
export interface UserType {
  id: string
  username: string
  email: string
  subscriptionLevel: SubscriptionLevel
  subscriptionStatus: SubscriptionStatus
  roles: string[]  // This is actually a list of permission strings
}

interface AuthState {
  isAuthenticated: boolean
  user: UserType | null
  token: string | null
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  initialized: boolean
  error: string | null
}

// Define the payload type for the login thunk
interface LoginPayload {
  username: string
  password: string
}

// Define the API response type
interface LoginApiResponse {
  accessToken: string
  id: string
  username: string
  email: string
  roles: string[]  // List of permission strings
  subscriptionLevel: SubscriptionLevel
  subscriptionStatus: SubscriptionStatus
}

// Define the response type
interface LoginResponse {
  token: string
  user: UserType
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
  loading: 'idle',
  initialized: false,
  error: null,
}

// Thunks
export const login = createAsyncThunk<LoginResponse, LoginPayload>(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post<LoginApiResponse>('/api/auth/signin', { 
        username, 
        password 
      })

      const { 
        accessToken, 
        id, 
        username: responseUsername, 
        email, 
        roles,
        subscriptionLevel,
        subscriptionStatus 
      } = response.data

      // Store the token in localStorage
      if (accessToken) {
        console.log('Received token:', accessToken);
        localStorage.setItem('token', accessToken)
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      }

      // Return structured data
      return {
        token: accessToken,
        user: {
          id,
          username: responseUsername,
          email,
          roles,
          subscriptionLevel,
          subscriptionStatus
        },
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        return rejectWithValue(error.response.data.message)
      }
      return rejectWithValue('Login failed')
    }
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      return null
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Logout failed')
    }
  }
)

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<UserType>('/api/auth/me')
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to get user')
    }
  }
)

export const updateSubscriptionStatus = createAsyncThunk(
  'auth/updateSubscriptionStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<UserType>('/api/auth/me')
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update subscription status')
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    restoreToken: (state) => {
      const token = localStorage.getItem('token')
      if (token) {
        state.token = token
        state.isAuthenticated = true
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }
      state.initialized = true  // Add initialization here
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = 'pending'
        state.error = null
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.user = action.payload.user
        state.token = action.payload.token
        state.loading = 'succeeded'
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.payload as string || 'Login failed'
        state.isAuthenticated = false
        state.user = null
        state.token = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false
        state.user = null
        state.token = null
        state.error = null
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.user = action.payload
        state.loading = 'succeeded'
        state.initialized = true  // Mark initialized here
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.payload as string
        state.isAuthenticated = false
        state.user = null
        state.initialized = true  // Combine with existing rejection handler
      })
  },
})



export const { restoreToken } = authSlice.actions
export default authSlice.reducer